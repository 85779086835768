

















import { Vue, Component, Prop } from "vue-property-decorator";
import { scrollTo } from "@/utils/scroll-to";

@Component({
  name: "Pagination",
})
export default class extends Vue {
  @Prop({ required: true })
  total!: number;
  @Prop({ default: 0 })
  page!: number;
  @Prop({ default: 20 })
  limit!: number;
  @Prop({ default: () => [10, 20, 30, 50] })
  pageSizes!: number;
  @Prop({ default: "total, sizes, prev, pager, next, jumper" })
  layout!: string;
  @Prop({ default: true })
  background!: boolean;
  @Prop({ default: true })
  atuoScroll!: boolean;
  @Prop({ default: false })
  hidden!: boolean;
  @Prop({ default: true })
  autoScroll!: boolean;

  get currentPage() {
    return this.page;
  }

  set currentPage(value) {
    this.$emit("update:page", value);
  }

  get pageSize() {
    return this.limit;
  }

  set pageSize(value) {
    this.$emit("update:limit", value);
  }

  _handleSizeChange() {
    this.$emit("pagination", { page: this.currentPage });
    if (this.autoScroll) {
      scrollTo(0, 800);
    }
  }

  _handleCurrentChange(value: number) {
    this.$emit("pagination", {
      page: value,
      size: this.limit,
    });
    if (this.autoScroll) {
      scrollTo(0, 800);
    }
  }
}
