
























interface IBookingCountModel {
  allCount: number;
}

import { Component, Vue, Watch } from "vue-property-decorator";
import PanelGroup from "@/components/PanelGroup.vue";
import BookingTable from "./components/BookingTable.vue";
import ReservationEdit from "./components/ReservationEdit.vue";

import { ITabModel, IPanelData } from "@/data/commonModel";

import { UserModule } from "@/store/modules/user";
import { ReservationModule } from "@/store/modules/reservation";
import { BookingTimerModule } from "@/store/modules/bookingTimer";
import { IBookingModel } from "@/pages/booking/data/bookingModel";

// 装饰器模式
@Component({
  components: {
    PanelGroup,
    BookingTable,
    ReservationEdit,
  },
})
export default class extends Vue {
  // Data
  isPopupStoreManager: boolean = !!UserModule.wineries[0].roles.find(
    (role) => role.label === "Finance Team"
  );
  defaultActive: string = this.isPopupStoreManager ? "history" : "valid";
  panelObj: IPanelData = { type: this.defaultActive, searchText: "" ,show:true};
  editDialogVisible: boolean = false;
  editType: string = "new";
  bookingModel: IBookingModel | null = null;
  
  // Computed
  // Get
  get tabs(): ITabModel[] {
    const countRes = ReservationModule.countRes;

    if (this.isPopupStoreManager) {
      return [
        { title: `预约历史(${countRes.historyCount})`, key: "history" },
      ];
    }

    //  1、即将到店: 已确认的且预约时间为距此时此刻15分钟前至以后的预约
    //  2、已到店: admin点击过已到达的预约
    //  3、未到店: 已确认且超过约定预约时间15分钟以上的预约---未到店预约admin可以手动将其状态改至已到店
    //  4、审核中
    //  5、已取消：用户小程序端触发的取消预约+线下电话预约客户后主动要求取消，店员更改状态为取消
    //  6、预约历史：所有距此时此刻15分钟以前的预约 （包括各状态：已到店，未到店， 已取消，已拒绝）
    const arr = [
      { title: `即将到店(${countRes.validCount})`, key: "valid" },
      { title: `已经到店(${countRes.completedCount})`, key: "completed" },
      { title: `未到店(${countRes.expiredCount})`, key: "expired" },
      { title: `审核中(${countRes.refundRequestCount})`, key: "verify" },
      { title: `已取消(${countRes.closedCount})`, key: "refunded" },
      { title: `预约历史(${countRes.historyCount})`, key: "history" },
    ];

    return arr;
  }

  get switchToComingSoonTab() {
    return BookingTimerModule.switchToComingSoonTab;
  }

  // Watch
  @Watch("switchToComingSoonTab", {
    immediate: true,
  })
  onSwitchToComingSoonTabChange(value: boolean) {
    if (value) {
      this.defaultActive =
        this.defaultActive === "comingSoon2" ? "comingSoon" : "comingSoon2";
      // 用于防止当前type本身就是待确认时，watch方法不会执行， table无法刷新的情况
      this.panelObj.type =
        this.panelObj.type === "comingSoon" ? "comingSoon2" : "comingSoon";
      BookingTimerModule.RESETFLAG();
    } else if (this.defaultActive === "comingSoon") {
      this.defaultActive = "";
    }
  }

  // Methods
  mounted() {
    if (
      this.$router.currentRoute &&
      this.$router.currentRoute.query &&
      this.$router.currentRoute.query.type
    ) {
      // @ts-ignore
      this.defaultActive = this.$router.currentRoute.query.type;
      this.panelObj.type = this.defaultActive;
    }
    ReservationModule.FetchReservationCountByStauts();
    UserModule.addBarChangeCallBack(() => {
      const ref: BookingTable = this.$refs["booking-table"] as BookingTable;
      ref.refreshTable();
    });
  }

  // 切换选项卡
  _handleSwitchTab(props: IPanelData) {
    this.panelObj = props;
  }

  _handleClickEdit(type: string, bookingModel: IBookingModel | null = null) {
    this.editDialogVisible = true;
    console.log("_handleClickEdit",bookingModel)
    this.editType = type;
    this.bookingModel = bookingModel;
  }

  _handleEditReservationSuccess() {
    const ref: BookingTable = this.$refs["booking-table"] as BookingTable;
    ref.refreshTable();
  }
}
