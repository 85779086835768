import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators'
import store from '@/store'
import { getReservationCountByStatus } from '@/remote/booking'
import { IBookingCountRes } from '@/pages/booking/data/bookingModel'

export interface IReservationState {
  countRes: IBookingCountRes
}

@Module({ dynamic: true, store, name: 'reservation' })
class Reservation extends VuexModule implements IReservationState {
  public countRes = {
  validCount: 0,
  completedCount: 0,
  expiredCount: 0,
  refundRequestCount: 0,
  closedCount: 0,
  historyCount: 0, 
  }

  @Action
  public async FetchReservationCountByStauts() {
    try {
      const res = await getReservationCountByStatus()
      if (res && res.data && res.data.data) {
        this.SET_RESERVATION_COUNT_RES(res.data.data)
      }
    } catch (error) {
      // console.log('error' + error)
    }
  }

  @Mutation
  SET_RESERVATION_COUNT_RES(res: IBookingCountRes) {
    this.countRes = res
  }
}

export const ReservationModule = getModule(Reservation)
