


































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { IInvitationModel } from "@/data/commonModel";
import { useInvitation } from "@/remote/custom";
import moment from "moment";

// 装饰器模式
@Component({
  components: {},
})
export default class extends Vue {
  // Props
  // 显示或隐藏
  @Prop({ default: false })
  dialogVisible!: boolean;
  @Prop() invitation!: IInvitationModel;

  // Data
  useBtnLoading: boolean = false;

  // Computed
  get expiredTime() {
    if (!this.invitation) return "";
    return moment(this.invitation.expiredAt).format("YYYY-MM-DD HH:mm");
  }

  // Methods
  _beforeModalClose() {
    this._hide();
  }

  _hide() {
    this.$emit("update:dialogVisible", false); // 直接修改父组件的属性
  }

  _onClickCancel() {
    this._hide();
  }

  async _onClickConfirm() {
    this.useBtnLoading = true;
    try {
      const res = await useInvitation(this.invitation.id);
      this._hide();
      this.useBtnLoading = false;
      this.$emit("onUseInvitationSuccess");
    } catch {
      // console.log("useInvitation 出错了");
      this.useBtnLoading = false;
    }
  }
}
