import http from './interface'
import { IClientListQuery } from '@/pages/client/data/clientModel'

// 获取所有顾客列表
export const getCustomList = (data: IClientListQuery) => {
  const { page, size, searchText } = data
  return http.get(
    `/api/v3/users?page=${page}&size=${size}&nameOrNumber=${searchText}`
  )
}

// // 修改顾客会员等级
// export const updateCustomVipLevel = (userId: string, vipLevelId: string) => {
//   return http.patch(`/api/v3/users/${userId}/vipLevel`, { vipLevelId })
// }

// 使用邀请函
export const useInvitation = (invitationLetterId: string) => {
  return http.post(`/api/v3/invitationletters/${invitationLetterId}/apply`)
}

export const exportCustomList = () => {
  return http.get('/api/v3/users/export', {
    responseType: 'blob' //告诉服务器我们需要的响应格式
  })
}
