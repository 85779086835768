

















































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Pagination from "@/components/pagination/Pagination.vue";
import moment from "moment";
import { ReservationModule } from "@/store/modules/reservation";
import { IPanelData, IInvitationModel } from "@/data/commonModel";
import {
  IBookingModel,
  IBookingListQuery,
  IProductItems,
  ISetArriveReqModel,
  IProduceReqModel,
  IBookingProductSelectionReqItemModel,
  IBookingProductSelectionReqModel,
  IBookProducts,
  IBookingProductItems,
} from "../data/bookingModel";
import {
  getReservationRecords,
  downloadReservationRecords,
  setArrived,
  refund,
  selectVochers,
  acceptRefund,
  rejectRefund,
  getRefundRequests,
  updateReservationArrivalStatus,
} from "@/remote/booking";
// import { useInvitation } from '@/remote/custom'
import PopupUseInvitation from "@/components/PopupUseInvitation.vue";
import { UserModule } from "@/store/modules/user";
import { getProductItems } from "@/remote/product";

// 装饰器模式
@Component({
  components: {
    Pagination,
    PopupUseInvitation,
  },
})
export default class extends Vue {
  // Props
  @Prop({
    required: true,
  })
  panelObj!: IPanelData;

  // Data
  dialogVisible: boolean = false;
  curIndex = -1;
  curInvitation: IInvitationModel | null = null;
  smallColumnLableWidth: string = "55px";
  listLoading: boolean = false;
  list: IBookingModel[] = [];
  curSelectProductNum: number = 0;
  maxSelectProductNum: number = 3;
  totalSelectProductNum: number = 3;
  bookingProducts: IBookProducts[] = [];
  bookedProducts: IBookProducts[] = [];

  totalSize = 0;
  isPopupStoreManager: boolean = !!UserModule.wineries[0].roles.find(
    (role) => role.label === "Finance Team"
  );
  listQuery: IBookingListQuery = {
    page: 1,
    size: 10,
    searchText: this.panelObj.searchText,
    status: this.isPopupStoreManager
      ? "completed,expired,refunded"
      : "comingSoon",
    bookingTime: "",
    guestNumber: "",
    start: "",
    to: "",
  };
  exportButtonLoading = false;
  timeRanges: string[] = [moment().format("YYYY-MM-DD"), ""];

  // 部分到店
  visible: boolean = false;
  selectProductVisible: boolean = false;
  maxNum: Number = 1;
  num: Number = 1;
  rowId: string = "";
  curOpModel?: IBookingModel;
  items: IBookingProductItems[] = [];
  loading: boolean = false;

  reFundVisible: boolean = false;

  isDoing: boolean = false;

  datePickerOptions = {
    disabledDate(time: Date) {
      return (
        time.getTime() < Date.now() - 24 * 60 * 60 * 1000 ||
        moment(time).isAfter(moment().add(180, "d"))
      );
    },
  };

  //  已到店、未到店、已取消
  unlimitPickerOptions = {
    disabledDate(time: Date) {
      return (
        moment(time).isBefore(moment("2020-01-01")) ||
        moment(time).isAfter(moment().add(180, "d"))
      );
    },
  };

  historyDatePickerOptions = {
    disabledDate(time: Date) {
      return (
        moment(time).isAfter(moment()) ||
        moment(time).isBefore(moment("2020-01-01"))
      );
    },
  };

  // Watch
  @Watch("panelObj", { deep: true, immediate: true })
  _onPanelObjChange(value: IPanelData) {
    if (value.type === "valid") {
      this.listQuery.status = "valid";
    } else {
      this.listQuery.status = value.type;
    }
    this._resetTimeRange();
    this.listQuery.page = 1;
    this.listQuery.searchText = value.searchText;

    this.bookingProducts.forEach((element) => {
      element.select = 0;
    });
    this.curSelectProductNum = 0;
    this._fetchRservationRecords(this.listQuery);
  }

  public async refreshTable() {
    this._fetchRservationRecords(this.listQuery);
  }

  async _fetchRservationRecords(params: IBookingListQuery) {
    console.log("_fetchRservationRecords");
    this.listLoading = true;
    try {
      console.log(params.status);
      let res;
      if ("verify" != params.status) {
        if ("history" === params.status) {
          params.status = "completed,expired,refunded";
        }
        res = await getReservationRecords(params);
      } else {
        res = await getRefundRequests(params);
      }

      console.log(res);
      if (res && res.data && res.data.data) {
        var { total, items } = res.data.data;
        console.log(total);
        this.totalSize = total;
        items.forEach((element: IBookingModel) => {
          element.name = element.customerLastName + element.customerFirstName;
          element.timeSlotText =
            element.timeSlot.startAt ;
          element.pendingProducts = "";
          element.pendingProductsNumber = 0;
          element.maxProductsNumber = 0;
          element.itemsNew = [];
          element.vouchersIds = [];
          element.vouchers.forEach((product) => {
            //针对套餐进行分类
            const products = element.itemsNew.filter((item) => {
              return item.productId === product.productId;
            });
            const newBookingProductItem = products.length
              ? products[0]
              : {
                  productId: product.productId,
                  totalPrice: 0,
                  unitPrice: product.unitPrice,
                  totalQuantity: 0,
                  redeemedQuantity: 0,
                  refundedQuantity: 0,
                  refundingQuantity: 0,
                  validQuantity: 0,
                  pendingQuantity: 0,
                  expiredQuantity:0,
                  title: product.title,
                  items: [],
                  quantity: 0,
                };
            newBookingProductItem.items.push(product);
            newBookingProductItem.totalQuantity++;
            if (product.isLocked) {
              newBookingProductItem.refundingQuantity++;
            }
            if (
              product.type === "customizable" &&
              product.status !== "refunded"
            ) {
              element.maxProductsNumber++;
              element.pendingProducts = product.productId;
            }
            if (
              product.type === "customizable" &&
              product.status === "pending"
            ) {
              newBookingProductItem.pendingQuantity++;
              element.pendingProductsNumber++;
              element.vouchersIds.push(product.id);
            } else if (product.status === "valid") {
              newBookingProductItem.validQuantity++;
            } else if (product.status === "redeemed") {
              element.hasConsumed = true;
              newBookingProductItem.redeemedQuantity++;
            } else if (product.status === "refunded") {
              newBookingProductItem.refundedQuantity++;
            }
            if (!products.length) {
              element.itemsNew.push(newBookingProductItem);
            }
          });

          element.itemsNew.forEach((product) => {
            product.totalPrice =
              product.unitPrice *
              (product.totalQuantity - product.refundedQuantity);
          });
          element.genderText =
            element.customerGender === "male" ? "先生" : "女士";

          if (
            element.latestRefundRequest &&
            element.latestRefundRequest.status === "pending"
          ) {
            element.hasVerify = true;
          }
          if (element.status === "valid") {
            element.statusDes = "即将到店";
          } else if (element.status === "completed") {
            element.statusDes = "已经到店";
          } else if (element.status === "expired") {
            element.statusDes = "未到店";
          } else if (element.status === "refunded") {
            element.statusDes = "已取消";
          } else if (element.status === "reserved") {
            // 2
            element.statusDes = "未支付";
          } else if (element.status === "refunded") {
            element.statusDes = "已取消";
          }

          // mock data
          // element.arrivalNumber = 2;
        });

        this.list = items;
        console.log("ddkkkk");
        console.log(this.list);
        this.listLoading = false;
        ReservationModule.FetchReservationCountByStauts();
      }
    } catch {
      if (params.page === 1) {
        this.list = [];
      }
      this.listLoading = false;
      // console.log("getReservationRecords 出错了");
    }
  }

  _resetTimeRange() {
    if (this.listQuery.status === "valid") {
      this.timeRanges = [moment().format("YYYY-MM-DD"), ""];
    } else {
      this.timeRanges = ["2020-01-01", ""];
    }
    this.listQuery.start = "";
    this.listQuery.to = "";
  }

  _onChangeTimeRange(event: any) {
    if (typeof event === "string") {
      switch (event) {
        case "all":
          this._resetTimeRange();
          break;
        case "today":
          this.timeRanges = [
            moment().format("YYYYMMDD"),
            moment().format("YYYYMMDD"),
          ];
          this.listQuery.start = this.timeRanges[0];
          this.listQuery.to = this.timeRanges[1];
          break;
        case "futureOneWeek":
          this.timeRanges = [
            moment().format("YYYYMMDD"),
            moment().add(1, "week").format("YYYYMMDD"),
          ];
          this.listQuery.start = this.timeRanges[0];
          this.listQuery.to = this.timeRanges[1];
          break;
        default:
          break;
      }
    } else {
      this.listQuery.start = moment(event[0]).format("YYYYMMDD");
      this.listQuery.to = moment(event[1]).format("YYYYMMDD");
    }
    this.listQuery.page = 1;
    this.refreshTable();
  }

  async _setArrived(row: any) {
    if (row.pendingProductsNumber > 0) {
      this.$message.error("该笔订单未完成商品选择，请先选择商品");
      return;
    }
    this.curOpModel = row;
    this.curOpModel?.itemsNew.forEach((item: IBookingProductItems) => {
      item.quantity = item.validQuantity;
    });
    this.items = JSON.parse(JSON.stringify(this.curOpModel!.itemsNew));
    this.visible = true;
  }
  productMinus(value: any, item: IBookProducts) {
    if (item.select > 0) {
      item.select--;
      this.curSelectProductNum--;
    }
  }
  productPlus(value: any, item: IBookProducts) {
    if (this.curSelectProductNum < this.maxSelectProductNum) {
      item.select++;
      this.curSelectProductNum++;
    }
  }

  async _setProduct(row: any) {
    if (this.isDoing) {
      return;
    }
    this.isDoing = true;
    this.curOpModel = row;
    this.bookingProducts = [];
    this.bookedProducts = [];
    this.curSelectProductNum = 0;
    this.totalSelectProductNum = this.curOpModel!.maxProductsNumber;
    this.maxSelectProductNum = this.curOpModel!.pendingProductsNumber;
    if (this.curOpModel!.pendingProducts.trim().length > 0) {
      var res = await getProductItems(this.curOpModel!.pendingProducts);
      console.log(res);
      res.data.data.forEach((element: IBookProducts) => {
        element.select = 0;
        element.vouchersIds = this.curOpModel!.vouchersIds;
        this.bookingProducts.push(element);
      });
    }
    console.log(this.bookingProducts);
    this.curOpModel?.vouchers.forEach((element) => {
      console.log("element", element, element.sku);
      if (
        element.type == "customizable" &&
        element.status !== "refunded" &&
        element.sku !== null
      ) {
        const selectProduct = this.bookingProducts.filter((product) => {
          console.log(
            "product",
            product,
            product.id,
            product.id === element.sku
          );
          return product.sku === element.sku;
        });
        console.log("selectProduct", selectProduct);
        if (selectProduct.length) {
          const selectProduct2 = this.bookedProducts.filter((product) => {
            return product.sku === element.sku;
          });
          console.log("selectProduct2", selectProduct2);
          if (selectProduct2.length) {
            selectProduct2[0].select++;
          } else {
            this.bookedProducts.push({
              select: 1,
              title: selectProduct[0].title,
              sku: selectProduct[0].sku,
              productId: selectProduct[0].productId,
              vouchersIds: selectProduct[0].vouchersIds,
              id: selectProduct[0].id,
            });
          }
        }
      }
    });
    console.log(this.bookedProducts);
    this.isDoing = false;
    this.selectProductVisible = true;
  }

  _handleSelectProuctNumChange(value: any, item: IBookProducts) {
    console.log(value);

    var selectNum = 0;
    console.log(this.curSelectProductNum);
    this.bookingProducts.forEach((element) => {
      console.log("element.select " + element.select);
      selectNum += element.select;
    });

    this.curSelectProductNum = selectNum;
    if (this.curSelectProductNum > this.maxSelectProductNum) {
      item.select--;
      console.log("超过最大值了");
    }
    console.log("_handleSelectProuctNumChange: " + this.curSelectProductNum);
    console.log(this.bookingProducts);
  }
  async _cancelReservation(index: number) {
    console.log(index);
    if (this.list[index].latestRefundRequest?.status == "pending") {
      this.$message.error("该笔订单目前有部分退款正在等待审核，请先完成审核");
      return;
    }
    this.curOpModel = this.list[index];
    this.curOpModel?.itemsNew.forEach((item: IBookingProductItems) => {
      item.quantity =
        item.validQuantity >= 1 || item.pendingQuantity >= 1 ? 1 : 0;
    });
    this.items = JSON.parse(JSON.stringify(this.curOpModel!.itemsNew));
    this.reFundVisible = true;
  }

  _onClickCommand(command: string, reservationId: string, index: number) {
    switch (command) {
      case "accept-refund":
        this._showToast("acceptRefund", "确认同意退款吗？", index);
        break;
      case "reject-refund":
        this._showToast("rejectRefund", "确认拒绝退款吗？", index);
        break;
      case "cancel-reservation":
        this._cancelReservation(index);
        break;
      case "edit-client-info":
        this._editClientInfo(index);
        break;
      default:
        break;
    }
  }

  async _postReservationArrivalStatus(
    reservationId: string,
    isArrival: boolean,
    index: number
  ) {
    const res = await updateReservationArrivalStatus(reservationId, isArrival);
    if (res && res.status === 200) {
      this.$message.success("操作成功");
      // this.list[index].isArrivaled = false;
      if (this.list.length === 1 && this.listQuery.page > 1) {
        this.listQuery.page -= 1;
      }
      this.refreshTable();
    }
  }

  _showToast(type: string, title: string, index: number) {
    this.$confirm(title, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
      customClass: "myConfirm",
    })
      .then(() => {
        this._updateReservationStauts(type, index);
      })
      .catch(() => {});
  }

  async _updateReservationStauts(type: string, index: number) {
    // 仅定义了 unattended、 cancel、 acceptRefund、 rejectRefund
    try {
      var res: any = null;
      this.listLoading = true;
      if (type === "acceptRefund") {
        res = await acceptRefund(this.list[index].latestRefundRequestId);
      } else if (type === "rejectRefund") {
        res = await rejectRefund(this.list[index].latestRefundRequestId);
      }

      this.$message.success("操作成功");
      if (this.list.length === 1 && this.listQuery.page > 1) {
        this.listQuery.page -= 1;
      }
      this.refreshTable();

      // else if (type === "accept") {
      //   res = await acceptReservation(reservationId);
      // }
      // || type === "reject" rejectReservation

      // if (type === "accept" && res && res.data && res.data.data) {
      //   // 不可达
      //   const { success, seatRemaining } = res.data.data;
      //   if (success) {
      //     this.$message.success("操作成功");
      //     if (this.list.length === 1 && this.listQuery.page > 1) {
      //       this.listQuery.page -= 1;
      //     }
      //     this.refreshTable();
      //   } else {
      //     // 如果在确认人数已满前申请预约 但是等待确认的过程中该时段预约剩余人数后来变为小于需通过确认同意的人数 admin如果点击接受则提示弹窗【该时段剩余可预约席位数为X（x就是剩余的空位），空余席位不足，请拒绝该预约。】 下方两个按钮 “稍后再说” “拒绝该预约”点击拒绝就直接拒绝预约了
      //     const title = `该时段剩余可预约席位数为${seatRemaining}，空余席位不足，请拒绝该预约。`;
      //     this.$confirm(title, "提示", {
      //       confirmButtonText: "拒绝该预约",
      //       cancelButtonText: "稍后再说",
      //       center: true,
      //     })
      //       .then(() => {
      //         this._updateReservationStauts("reject", reservationId);
      //       })
      //       .catch(() => {});
      //   }
      // } else if (
      //   res &&
      //   res.status === 200 &&
      //   (type === "acceptRefund" ||
      //     type === "rejectRefund" ||
      //     type === "cancel")
      // ) {
      //   this.$message.success("操作成功");
      //   if (this.list.length === 1 && this.listQuery.page > 1) {
      //     this.listQuery.page -= 1;
      //   }
      //   this.refreshTable();
      // }
      this.listLoading = false;
    } catch {
      this.listLoading = false;
    }
  }

  // 编辑预约信息
  _editClientInfo(index: number) {
    this.$emit("onClickEdit", "edit", this.list[index]);
  }

  // 通过这一条将row的index在分页情况下进行校正
  tableRowClassName(row: any) {
    row.row.index =
      row.rowIndex + (this.listQuery.page - 1) * this.listQuery.size;
  }

  _onSortChange(column: any) {
    const { order, prop } = column;
    if (prop === "arrivalDate") {
      if (!order) {
        this.listQuery.guestNumber = "";
      } else if (order === "descending") {
        this.listQuery.bookingTime = "desc";
      } else {
        this.listQuery.bookingTime = "asc";
      }
    } else if (prop === "guestNumber") {
      this.listQuery.bookingTime = "";
      if (!order) {
        this.listQuery.guestNumber = "";
      } else if (order === "descending") {
        this.listQuery.guestNumber = "desc";
      } else {
        this.listQuery.guestNumber = "asc";
      }
    }
    this._fetchRservationRecords(this.listQuery);
  }

  async _onClickDownload() {
    this.exportButtonLoading = true;
    try {
      const res = await downloadReservationRecords(this.listQuery);
      if (!res || !res.data) return;
      let a = document.createElement("a");
      let blob = new Blob([res.data], {
        type: "application/octet-stream", //这里放文件类型，后台返回的response会有这个文件类型，放进来就行
      });
      a.href = window.URL.createObjectURL(blob);
      a.download = "预约信息.xlsx";
      a.click();
      this.exportButtonLoading = false;
    } catch {
      this.exportButtonLoading = false;
      this.$message.error("导出失败，请重试");
      // console.log("exportCustomList 出错了");
    }
  }

  _onClickAdd() {
    this.$emit("onClickEdit", "add");
  }

  _updatePage(value: any) {
    this.listQuery.page = value.page;
    this.refreshTable();
  }

  _arriveModuleVisible(value: IBookingModel): boolean {
    const { status } = value;
    // pls search: element.statusDes
    // if ([2, 3, 8].includes(status)) {
    //   return true;
    // }
    return false;
  }

  async _onClickConfirm() {
    this.loading = true;
    try {
      const productList: IProduceReqModel[] = [];
      if (this.items && this.items.length > 0) {
        this.items.forEach((element: IBookingProductItems) => {
          console.log(element);
          if (element.quantity > 0) {
            productList.push({
              productId: element.productId,
              quantity: element.quantity,
            });
          }
        });
      }

      const reqModel: ISetArriveReqModel = {
        items: productList,
      };
      const res = await setArrived(this.curOpModel!.id, reqModel);
      this.loading = false;

      if (res.data.resultCode === 0) {
        this.visible = false;

        if (this.list.length === 1 && this.listQuery.page > 1) {
          this.listQuery.page -= 1;
        }
        this.refreshTable();
      } else {
        this.$message.error("操作失败。");
      }
    } catch {
      this.$message.error("操作失败。");
      this.loading = false;
    }
  }
  async _onClickConfirmProduct() {
    this.loading = true;
    console.log("_onClickConfirmProduct");
    console.log(this.bookingProducts);

    try {
      const productList: IBookingProductSelectionReqItemModel[] = [];
      if (this.bookingProducts && this.bookingProducts.length > 0) {
        var vouchersIndex = 0;
        this.bookingProducts.forEach((element: IBookProducts) => {
          console.log(element);
          if (element.select > 0) {
            for (var i = 0; i < element.select; i++) {
              console.log(element.vouchersIds[vouchersIndex]);
              productList.push({
                voucherId: element.vouchersIds[vouchersIndex],
                sku: element.sku,
              });
              vouchersIndex++;
            }
          }
        });
      }

      const reqModel: IBookingProductSelectionReqModel = {
        selections: productList,
      };
      console.log(reqModel);
      const res = await selectVochers(this.curOpModel!.id, reqModel);
      this.loading = false;

      if (res.data.resultCode === 0) {
        this.selectProductVisible = false;

        if (this.list.length === 1 && this.listQuery.page > 1) {
          this.listQuery.page -= 1;
        }
        this.refreshTable();
      } else {
        this.$message.error("操作失败。");
      }
    } catch {
      this.$message.error("操作失败。");
      this.loading = false;
    }
  }
  async _onClickRefund() {
    this.loading = true;
    let isAll: boolean = true;
    try {
      const productList: IProduceReqModel[] = [];
      if (this.items && this.items.length > 0) {
        this.items.forEach((element: IBookingProductItems) => {
          console.log(element);
          if (element.quantity > 0) {
            productList.push({
              productId: element.productId,
              quantity: element.quantity,
            });
          }
          if (
            element.quantity <
            element.validQuantity + element.pendingQuantity
          ) {
            isAll = false;
          }
        });
      }
      const res = await refund(
        isAll ? "entire" : "partial",
        this.curOpModel!.id,
        isAll ? [] : productList
      );
      setTimeout(() => {
        this.loading = false;
        if (res.data.resultCode === 0) {
          this.visible = false;
          if (this.list.length === 1 && this.listQuery.page > 1) {
            this.listQuery.page -= 1;
          }
          this.reFundVisible = false;
          this.refreshTable();
        } else {
          this.$message.error("操作失败。");
        }
      }, 3000);
    } catch {
      this.$message.error("操作失败。");
      this.loading = false;
    }
  }
  _hide() {
    this.visible = false;
    this.selectProductVisible = false;
    this.reFundVisible = false;
  }
}
