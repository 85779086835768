import http from './interface'
import { UserModule } from '@/store/modules/user'

// 上传文件id
export const uploadFileId = (fileId: string) => {
  return http.post(
    `/api/v3/wineries/${UserModule.wineryId}/products/update-products-by-file/${fileId}`
  )
}

// 获取当前生效的商品表格信息
export const getCurProductFileInfo = () => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/products/file`)
}

// 获取商品列表
export const getProductList = () => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/products/admin`)
}

// 获取商品列表
export const getProductFile = () => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/products/download/catalog`)
}

// 获取商品可选项目
export const getProductItems = (prodictId:string) => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/products/${prodictId}/optionalItems`)
}

