


































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ITabModel, IPanelData } from "@/data/commonModel";

// 装饰器模式
@Component({
  components: {},
})
export default class extends Vue {
  // Props
  @Prop({ required: true })
  tabs!: ITabModel[];
  @Prop({ required: true })
  defaultActive!: string;
  @Prop()
  placeholder!: string;
  @Prop({ default: "" })
  // 在指定的type下隐藏输入框
  hideInputInSpecificType!: string;
  // 是否隐藏输入框
  @Prop({ default: false })
  hideSearchbar!: string;
  @Prop({ default: { type: "", searchText: "",show:true } })
  panelData!: IPanelData;

  // Watch
  @Watch("defaultActive", { immediate: true })
  onDefaultActiveChange(value: string) {
    if (value && value.length > 0) {
      if (value === "comingSoon2") {
        this.panelData.type = "comingSoon";
      } else {
        this.panelData.type = value;
      }
    }
  }

  onSwitchTab() {
    this.$emit("onSwitchTab", this.panelData);
  }

  onClickSearch() {
    this.$emit("onClickSearch", this.panelData);
  }
}
