































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
// import { UserModule } from "@/store/modules/user";
import moment from "moment";
import DayBookingDetails from "@/components/DayBookingDetails.vue";
import { IBookingDetails } from "@/data/commonModel";
import { Form as ElForm } from "element-ui";
import { IBookingReqModel, IBookingModel ,IBookingLogs} from "../data/bookingModel";
import {
  addReservation,
  updateReservation,
  bookLogs,
} from "@/remote/booking";

interface IReservationFormModel {
  customerFirstName: string;
  customerLastName: string;
  customerPhoneNumber: string;
  userRemark: string;
  adminRemark: string;
  guestNumber: number;
  genderText: string;
  arrivalDate: Date | null;
  arrivalTime: string;
  code: string;
}

// 装饰器模式
@Component({
  components: {
    DayBookingDetails,
  },
})
export default class extends Vue {
  // Props
  // 显示或隐藏
  @Prop({ default: false })
  dialogFormVisible!: boolean;
  // 编辑类型
  @Prop({ default: "add" })
  editType!: string;
  // editType = 'edit'时所需要的数据
  @Prop() bookingModel!: IBookingModel;

  isInfo: boolean = true;
  // Data
  bookingId: string = "";
  bookingLogs:IBookingLogs[]=[];
  disabledDates: string[] = [];
  bookingDetails: IBookingDetails[] = [];
  formLabelWidth: string = "200px";
  saveBtnLoading: boolean = false;
  bookingDate: string = moment().add(1, "d").format("YYYY-MM-DD");
  formParams: IBookingModel = { 
    hasVerify: false,
    id: "",
    status: "",
    statusDes: "",
    date: "",
    adminRemark: "",
    userRemark: "",
    customerPhoneNumber: "",
    customerFirstName: "",
    customerLastName: "",
    name: "",
    code: "",
    customerGender: "",
    hasConsumed: false,
    genderText: "", // 中文
    latestRefundRequestId: "",
    latestRefundRequest: {
      amount: 0,
      status: "",
    },
    timeSlot: {
      startAt: "",
      endAt: "",
    },
    timeSlotText: "", 
    itemsNew:[],
    pendingProducts:"",
    pendingProductsNumber:0,
    maxProductsNumber:0,
    vouchers:[],
    vouchersIds:[]
  };

  rules = {};

  datePickerOptions = this._disabledDate();

  // Watch
  @Watch("bookingModel", { immediate: true, deep: true })
  onBookingModelChange(value: IBookingModel) {
    console.log("onBookingModelChange");
    console.log(value);
    if (!value) return;
    const model = JSON.parse(JSON.stringify(value));
    this.bookingId = model.id;
    this.formParams = value;
this._getBookLogs()
  }
 async _getBookLogs(){
const res =await bookLogs(this.bookingId)
if(res?.data?.data){
  this.bookingLogs=res.data.data
  console.log(this.bookingLogs)
}
  }

  _disabledDate() {
    const that = this;
    return {
      disabledDate(time: Date) {
        const timeStr = moment(time).format("YYYY-MM-DD");
        if (time.getTime() < Date.now() - 24 * 60 * 60 * 1000) {
          return true;
        } else if (that.disabledDates.includes(timeStr)) {
          return true;
        }
        return false;
      },
    };
  }

  _showChange(index: number) {
    console.log(index);
    if (index == 0) {
      this.isInfo = true;
    } else {
      this.isInfo = false;
    }
  }
  _hide() {
    this.$emit("update:dialogFormVisible", false);
  }

  _beforeModalClose() {
    this._hide();
  }

  _onClickConfirm() {
    const form = this.$refs["reservationForm"] as ElForm;
    form.validate((valid: Boolean) => {
      if (valid) {
        this._showConfirmToast();
      } else {
        return false;
      }
    });
  }

  _onClickCancel() {
    this._hide();
  }

  _showConfirmToast() {
    this.$confirm("确认更新该预约吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._handleFormData();
      })
      .catch(() => {});
  }

  _handleFormData() {
    this.saveBtnLoading = true;
    const param = JSON.parse(JSON.stringify(this.formParams));
    param.arrivalDate = moment(param.arrivalDate).format("YYYY-MM-DD");

    const reqModel: IBookingReqModel = {
      ...param,
    };
    this._uploadFormData(reqModel);
  }

  async _uploadFormData(reqModel: IBookingReqModel) {
    try {
      var res = null;
      console.log(this.editType,reqModel)
      if (this.editType === "add") {
        res = await addReservation(reqModel);
      } else {
        res = await updateReservation(this.bookingId, reqModel);
      }
      if (res && res.status === 200) {
        this.$message.success(
          this.editType === "add" ? "保存成功" : "更新成功"
        );
        this.$emit("onEditReservationSuccess", this.editType);
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("_uploadFormData 出错了");
    }
  }
}
