















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { IBookingDetails } from "@/data/commonModel";
import { getBookingNumberByDate } from "@/remote/event";
// import { UserModule } from "@/store/modules/user";
import moment from "moment";

@Component({
  components: {},
})
export default class extends Vue {
  // Props
  @Prop({})
  date!: Date; // 需要查询的日期

  @Prop({
    default: [
      {
        name: "晨饮",
        bookingNumber: 0,
        seatNumber: 0,
        form: "",
        to: "",
      },
      {
        name: "下午茶",
        bookingNumber: 0,
        seatNumber: 0,
        form: "",
        to: "",
      },
      {
        name: "晚餐",
        bookingNumber: 0,
        seatNumber: 0,
        form: "",
        to: "",
      },
    ],
  })
  bookingDetails!: IBookingDetails[];

  // Watch
  @Watch("date", { immediate: true, deep: true })
  onDateChange(newValue: Date) {
    // console.log("onDateChange");
    if (newValue) {
      // console.log("onDateChange");
      this._fetchDayBookingDetails(moment(newValue).format("YYYY-MM-DD"));
    }
  }

  async _fetchDayBookingDetails(date: string) {
    // console.log("_fetchDayBookingDetails: " + date);
    try {
      const res = await getBookingNumberByDate(date);
      if (res && res.data && res.data.data) {
        const data = res.data.data;
        var list: any[] = [];
        data.forEach((element: any) => {
          const { bookingNumber, seatNumber, timeSlotType } = element;
          const { order, name, from, to } = timeSlotType;
          list.splice(order - 1, 1, {
            bookingNumber,
            seatNumber,
            name,
            from,
            to,
          });
          this.$emit("update:bookingDetails", list);
        });
      } else {
        this.$message.error("获取当日预约详情失败");
      }
    } catch (error) {
      this.$message.error("获取当日预约详情失败");
      // console.log("getBookingNumberByDate 出错了: ");
      // console.log(error);
    }
  }
}
